import styled, {} from 'styled-components';


export const Container = styled.div``;


export const Content = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: black;
  img{
    flex: 1;
    margin-top: 100px;
    max-width: 320px;
  }  


`;
