import styled, {css} from 'styled-components';
import { shade } from 'polished';


interface ColunaProps {
  tamanhoCol?:number;
  justifyContent?: 'left' | 'center' | 'right';
  hideCollumn?:boolean;
  sizeMobile?: number; 
  
}

interface LinhaProps {
  backgroundColor?:string;
  fontColor?:string;  
  estiloLinha?:number | undefined;
  cabecalho?:boolean;
}

interface LetterProps {
  backgroundColor?:string;
}

interface InformarProps {
  marcarInformar: boolean;
}
export const Container = styled.div`
  height: 100vh;
  display: flex;
  
  flex-direction: column;
  
`;
export const InfoCulto = styled.div`
  text-align: center;
  padding-top: 10px;
  font-family: 'Roboto';
  text-transform: uppercase;
  color: #060b26;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700; 
 
 span{
   color: red;
 }
`;
export const Content = styled.div`
  
 
  margin: auto;
  margin-top: 20px;
  padding-top: 20px;
  
  width: 98%;
`;

export const LinkToNext = styled.div`
  display: flex;
  
  justify-content: center;
  margin-right: 100px;
  margin-bottom: 10px;
  width: 200px;
  height: 40px;
  background-color: #060b26;
  border-radius: 10px;
  transition: background-color 0.3s;
    
  a {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: 700;

    
  }
  :hover{
      background-color: ${shade(-0.9, '#060b26')}
    }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  cursor:default;
  margin-bottom: 30px;
  


`;

export const Linha = styled.div<LinhaProps>`
  display: flex;
  border-bottom: 1px solid black;
  min-height: 70px;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #060b26;
  transition: background-color 0.3s;
  ${(props) =>
		props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
  `
}

${(props) =>
		props.estiloLinha &&
    css`
      background-color: ${props.estiloLinha%2===0?'#FFE0B2':'#FFF3E0'};
      :hover{
        background-color: ${shade(0.2, props.estiloLinha%2===0?'#FFE0B2':'#FFF3E0')};
}
  `
}

${(props) =>
		props.fontColor &&
    css`
      color: ${props.fontColor};
  `
}

@media(max-width: 800px){
    
    flex-wrap: wrap;
    ${(props) =>
		props.cabecalho  &&
    css`
      font-size: 1px;
      color: #060b26;
      min-height: 10px;
    `
}
  }
`;

export const ConjuntoColunas = styled.div`

  display: flex;
  
  flex-wrap: wrap;
  justify-content: space-around;
  
  @media(max-width: 800px){
    justify-content: flex-start;
    flex-direction: column;
    flex:1;

    
  }

`;

export const Coluna = styled.div<ColunaProps>`
  display: flex;
  
  align-items: center;
  
  
  ${(props) =>
		props.tamanhoCol &&
    css`
      width: ${props.tamanhoCol}px;
  `
}

${(props) =>
		props.justifyContent &&
    css`
      justify-content: ${props.justifyContent} ;
  `
}
@media(max-width: 800px){
  ${(props) =>
		props.hideCollumn &&
    css`
      display: none ;
      margin-top: 5px;
      margin-bottom: 5px;
  `
  
}
  justify-content: flex-start;
  margin-left: 5px;
  
  ${(props) =>
		props.sizeMobile &&
    css`
      width: ${props.sizeMobile}px;
  `
}




}
`;


export const LetraNome = styled.div<LetterProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  font-weight: 700;

  ${(props) =>
		props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
  `}

  @media(max-width: 800px){
    width: 40px;
    height: 40px;
  }
`;

export const ButtonMudarFila = styled.button`
width: 50px;
height: 40px;
border: 0px solid #060b26;
border-radius: 50%;
margin-left: 10px;
transition: background-color 0.3s;
background: green;
  svg {
    color: white;
    
  }

  :hover{
    border: 2px solid white;
    background-color: #060b26;
    
    svg {
      color: white;
    
  }
  }
`;
export const ButtonDelete = styled.button`
width: 50px;
height: 40px;
border: 0px solid #060b26;
border-radius: 50%;

margin-left: 10px;
margin-right: 10px;
background: green;
transition: background-color 0.3s;
  svg {
    color: white;
    
  }

  :hover{
    border: 2px solid white;
    background-color: #060b26;
    svg {
      color: white;
    
  }
  }
`;


export const ButtonInformado = styled.button<InformarProps>`
width: 50px;
height: 40px;
border: 0px solid #060b26;
border-radius: 50%;

background: green;
transition: background-color 0.3s;
  svg {
    color: white;
    
  }

  :hover{
    border: 2px solid white;
    background-color: #060b26;
    svg {
      color: white;
    
  }
  }

  ${(props) =>
		props.marcarInformar &&
    css`
      background-color: red;
  `}
`;

export const WhatsApp = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Imprimir = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #060b26;
  font-size: 20px;
  cursor: pointer;
  svg{
    width: 30px;
  }
`;