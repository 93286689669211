import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Routes';
import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import Worships from '../pages/Worships';
import ListRegisters from '../pages/Worships/ListRegisters';
import Versao from '../pages/Versao';
import Profile from '../pages/Profile';


const Routes: React.FC = () => (
	<Switch>
		<Route path="/" exact component={SignIn} />
		
		<Route path="/dashboard" component={Dashboard} isPrivate />
		<Route path="/moduleWorships" component={Worships} isPrivate />
		<Route path="/listRegisters" component={ListRegisters} isPrivate />
		<Route path="/versao" component={Versao} isPrivate />
		<Route path="/profile" component={Profile} isPrivate />

	</Switch>
);

export default Routes;
