import React from 'react';

import {
	Container, 
	Content, 
	Title
	
} from './styles';


import Navbar from '../../components/Navbar';





const Versao:React.FC = () => {
	return (
		<Container>
			<Navbar />
			
			<Content>
				<Title>NOTAS DE VERSÃO - V 0.05</Title>
				<div> - Possibilidade de alteração de perfil.(entrega: 06/10/2020)</div>
				<div> - Possibilidade de alteração de foto.(entrega: 06/10/2020)</div>
				<div> - Disponibilização da opçao de gerar relatório dos cultos.(entrega: 06/10/2020)</div>
				<div> - Alteração do layout da demonstração dos cultos disponíveis.(entrega: 06/10/2020)</div>	
				
				<Title>NOTAS DE VERSÃO - V 0.01</Title>
				<div> - Controle de usuário.(entrega: 22/09/2020)</div>
				<div> - Controle de inscrição para os cultos (entrega: 22/09/2020)</div>
			</Content>
		</Container>
	);
};


export default Versao;