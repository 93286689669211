import React from 'react';

import {
	Container, 
	Content, 
	
} from './styles';

import Navbar from '../../components/Navbar';
import logo from '../../assets/logo.svg';



const Dashboard:React.FC = () => {
	return (
		<Container>
			<Navbar />
			
			<Content>
				
				<img src={logo} alt="Comunidade Cristã Moriá"/>
				<span>Para verificar as novas funcionalidades<br />
					entre em notas da versão
				</span>
			</Content>
		</Container>
	);
};


export default Dashboard;