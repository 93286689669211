import styled, {css} from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
interface SpanProps {
  color:string;
  
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    
 
   
`;

export const Porcentagem = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
    cursor:pointer;
    span{
      
      font-size: 16px;
    }

    &+div {
    margin-left: 70px;
    }
`;

export const Circular = styled(CircularProgressbar)`
  width: 80px;
  

`;

export const FilaEspera = styled(CircularProgressbar)`
  width: 80px;
  

`;

export const Span = styled.span<SpanProps>`

${(props) =>
		props.color &&
    css`
      color: ${props.color};
  `}

`;

