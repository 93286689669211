import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Container,Content,AnimationContainer } from './styles';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn:React.FC = () => {
	const formRef = useRef<FormHandles>(null);
	const history = useHistory();
	const [load, setLoad] = useState(false);
	const { signIn } = useAuth();
	const { addToast } = useToast();

	const handleSubmit = useCallback(
		
		async (data: SignInFormData) => {
			setLoad(true);
			try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({email: Yup.string().required('E-mail obrigatório').email('Digite um email válido'),password: Yup.string().required('Senha obrigatória'),});

        await schema.validate(data, {
        	abortEarly: false,
        });
        await signIn({
        	email: data.email,
        	password: data.password,
        });

        history.push('/dashboard');
			} catch(error) {
				if (error instanceof Yup.ValidationError) {
					const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
				}
				addToast({
					type: 'error',
					title: 'Atenção',
					description: 'Usuário ou senha incorretos',
				});
				setLoad(false);
			}
		},
		[signIn, addToast, history],
	);


	return (


		<Container>
 
			<Content>
				<AnimationContainer>
					<img src={logoImg} alt="Comunidade Cristã Moriá"/>
					<Form onSubmit={handleSubmit} ref={formRef} >
						<h1>Faça seu Login</h1>
						<Input 
							name='email'
							icon={FiMail}
							type="email"
							placeholder="Digite seu e-mail"
						/>

						<Input 
							name='password'
							icon={FiLock}
							type="password"
							placeholder="Digite sua senha"
						/>
						<Button type="submit">{load?'Entrando' : 'Entrar'}</Button>
					</Form>
				</AnimationContainer>
			</Content>

		</Container>

		


	);
};


export default SignIn;