import React from 'react';
import { IconContext } from 'react-icons';

export interface IconProp {
  colorValue?: string;
  
}


const IconProvider: React.FC<IconProp> = ({ colorValue, children }) => {
	
	return (
		<IconContext.Provider value={{ color: colorValue }}>
			{children}
		</IconContext.Provider>
	);
};


export { IconProvider };
