import styled, {css} from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  sizeInput?:string;
  isFocused: boolean;
  isField: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`

    background: #232129;
    display: flex;
    align-items:center;
    justify-content: space-between;
    width: 340px;
    ${(props) =>
		props.sizeInput &&
    css`
      width: ${props.sizeInput}px;
    `}
    
    height: 54px;

    border: 2px solid #FFA000;
    border-radius: 10px;
    padding: 16px;

    input {
      background: transparent;
      border: 0;
      flex: 1;
      padding: 0 10px;
      color: #fff;
    }

    input::placeholder{
      color: #ccc;
    }

    & + div {
      margin-top: 8px;
    }
    ${(props) =>
		props.isErrored &&
    css`
      border: 2px solid #c53030;
    `}
  

  ${(props) =>
		props.isFocused &&
    css`
      color: #ff9000;
      border: 2px solid #ff9000;
    `}

  ${(props) =>
		props.isField &&
    css`
      color: #ff9000;
    `}



  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #f4ede8;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;