import React, {useCallback, useState} from 'react';
import { Link } from 'react-router-dom';
import logoImg from '../../assets/logo.png';
import { 
	Container,
	LinkNavBar, 
	NavBarContent,
	NavBarContentUser,
	NavbarContainer, 
	NavMenuItem,
	NavBarToogle,
	LiPerfil,
	Li
} from './styles';
import { FaBars } from 'react-icons/fa';
import { AiFillHome, AiOutlineClose } from 'react-icons/ai';
import { IoIosPaper } from 'react-icons/io';
import { FiPower } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';


const Navbar:React.FC = () => {
	const [sidebar, setSidebar] = useState(false);
	const { signOut, user } = useAuth();
	const showSidebar = useCallback(() => {

		setSidebar(!sidebar);

	}, [sidebar]);

	return (
		<Container>
			<LinkNavBar>
				<button>
					<FaBars onClick={showSidebar} />
				</button>
				<NavBarContent>
					<img src={logoImg} alt="Comunidade Moriá"/>
				</NavBarContent>
				<NavBarContentUser>
					<Link to="/profile">
						<img src={user.avatar_url} alt=""/>
						<strong>{user.name}</strong>
					</Link>
					<button type="button" onClick={signOut}>
						<FiPower />
					</button>
				</NavBarContentUser>
			</LinkNavBar>
			<NavbarContainer active={sidebar}>
				<NavMenuItem>
					<NavBarToogle>
						<button>
							<AiOutlineClose onClick={showSidebar}/>
							<img src={logoImg} alt="Comunidade Moriá"/>
						</button>
					</NavBarToogle>
					<LiPerfil>
						<Link to="/profile">
							<img src={user.avatar_url} alt=""/>
							<strong>{user.name}</strong>
						</Link>
					</LiPerfil>
					<Li>
						<Link to="/">
							<AiFillHome />
							<span>Home</span>
						</Link>
					</Li>
					<Li>
						<Link to="moduleWorships">
							<IoIosPaper />
							<span>Cultos</span>
						</Link>
					</Li>
					<Li>
						<Link to="versao">
							<IoIosPaper />
							<span>Notas de Versão</span>
						</Link>
					</Li>
				</NavMenuItem>
			</NavbarContainer>
		</Container>
	);
};


export default Navbar;