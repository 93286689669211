import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import './styles.css';

import {
	Container, 
	
	Porcentagem,
	Circular,
	FilaEspera,
	Span
	
} from './styles';

interface IProgressBarProps {
  idculto:number;
  descricaoCulto: string;
}

interface IRegisterProps {
	inscritos: number;
  filaespera: number;
  vagas:number;
  ocupacao:number;
 }



const BarraPorcentagemCulto:React.FC<IProgressBarProps> = ({idculto, descricaoCulto}:IProgressBarProps) => {
	const [lastRegister, setlastRegister] = useState<IRegisterProps>({} as IRegisterProps);
	const history = useHistory();
	
	useEffect(()=>{
		let isUnmount = false;
		api.get(`registereds/count/${idculto}`)
			.then((response) =>{
				if(!isUnmount){
					setlastRegister(response.data);
				}
				
			});

		return () => {
			isUnmount = true;
		};
	}, [lastRegister, idculto]);
  
	const handleRedirectToList = useCallback(({filaespera})=>{

		history.push({
			pathname: 'listRegisters',
			//search: '?query=abc',
			state: { 
				descricao:descricaoCulto,
				idculto,
				filaespera,
				tipoFila: (filaespera?'Fila de Espera':''),
				existeFilaEspera: (filaespera?false:(lastRegister.filaespera >0))
			}
		});

	},[lastRegister, descricaoCulto, history, idculto]);
	
	return (
		<Container>
			
			
			<Porcentagem onClick={()=>handleRedirectToList({filaespera:false})}>
				<Circular value={lastRegister.ocupacao} text={`${lastRegister.inscritos}`} />
				<Span color="#3e98c7">Ocupadas</Span>
			</Porcentagem>
			{lastRegister.filaespera >0 && 
<Porcentagem onClick={()=>handleRedirectToList({filaespera:true})}>
	<FilaEspera value={100} text={`${lastRegister.filaespera}`} className="CircularProgressbar-red"/>
	<Span color="red">Espera</Span>
</Porcentagem>
			}			
		</Container>
	);
};



export default BarraPorcentagemCulto;