import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
	avatar?: string;
	avatar_url: string;
}

interface AuthState {
  userToken: string;
  user: User;
}

interface SignCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
	const [data, setData] = useState<AuthState>(() => {
		const userToken = localStorage.getItem('@ccm:token');
		const user = localStorage.getItem('@ccm:user');

		if (userToken && user) {
			api.defaults.headers.authorization = `Bearer ${userToken}`;
			return { userToken, user: JSON.parse(user) };
		}
		
		return {} as AuthState;
	});

	const signIn = useCallback(async ({ email, password }) => {
		const response = await api.post('session', {
			email,
			password,
		});

		const { userToken, user } = response.data;
		console.log(response.data);
		localStorage.setItem('@ccm:token', userToken);
		localStorage.setItem('@ccm:user', JSON.stringify(user));
		api.defaults.headers.authorization = `Bearer ${userToken}`;
		setData({ userToken, user });
	}, []);

	const signOut = useCallback(() => {
		localStorage.removeItem('@ccm:token');
		localStorage.removeItem('@ccm:user');

		setData({} as AuthState);
	}, []);

	const updateUser = useCallback(
		(user: User) => {
			localStorage.setItem('@ccm:user', JSON.stringify(user));
			
			setData({
				userToken: data.userToken,
				user,
			});
		},
		[setData, data.userToken],
	);
	return (
		<AuthContext.Provider
			value={{ user: data.user, signIn, signOut, updateUser }}
		>
			{children}
		</AuthContext.Provider>
	);
};

function useAuth(): AuthContextData {
	
	const context = useContext(AuthContext);
	
	return context;
}
export { AuthProvider, useAuth };
