import styled, {} from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div``;
export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
  
`;

export const InternalContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  
`;
export const TitleCulto = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  color: #FF4500;
  text-transform: uppercase;
  padding-top: 20px;
`;


export const Cards = styled.div`
  display: flex;
  

  flex-wrap: wrap;
  padding: 30px;
  justify-content: space-around; 
  align-items: center;
  background-color: #fff9fe;
  width: 90%;
  margin-top: 20px;
  border-radius: 10px; 
  
`;

export const TopoCard = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 95%;
  margin-left: 6px;
`;

export const Card = styled.div`
  min-height: 220px;
  width: 320px;
  display: flex;
  transition: background-color 0.3s;
  flex-direction: column;
  margin-top: 20px;
  font-size: 1.5rem;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 9px 1px rgba(61, 61, 61, 0.63);
  -moz-box-shadow:    0px 0px 9px 1px rgba(61, 61, 61, 0.63);
  box-shadow:         0px 0px 9px 1px rgba(61, 61, 61, 0.63);
    
  &:active {
    background-color: ${shade(-0.7, '#060b26')};

  }


`;

export const Descricao = styled.div`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #757575;
  span{
    font-weight: 700;
    color: #424242;
  }
  
`;

export const Vagas = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #060b26;
  font-size: 50px;
`;

export const BottonCard = styled.div`
  padding: 0 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #ccc;
  margin-bottom: 10px;
  span{
    padding: 10px 0;
  }
`;






