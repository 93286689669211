import styled, {keyframes} from 'styled-components';
import { shade } from 'polished';


export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background-color: #060b26;
`;

export const Content = styled.div`
  display:flex;
  flex:1;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`;
export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;
 
  form{
    margin: 80px 0;
    text-align: center;

  }

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: ${shade(0.2, '#ff9000')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.3s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }

  img {
    width: 90%;
  }
`;

