import React, { useState, useEffect, useCallback } from 'react';
import {Link, useLocation} from 'react-router-dom';
import api from '../../../services/api';
import { useConfirm } from 'material-ui-confirm';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import {WhatsApp as WP, Print} from '@material-ui/icons';
import Navbar from '../../../components/Navbar';
import { useAuth } from '../../../hooks/auth';

import { Container,
	InfoCulto, 
	Content, 
	Table,
	Linha,
	ConjuntoColunas,
	Coluna,
	LetraNome,
	ButtonInformado,
	ButtonMudarFila,
	ButtonDelete,
	LinkToNext,
	WhatsApp,
	Imprimir
} from './styles';

interface IActionsProps{
	idexcluir: number;
	nome: string;
}

interface IRegisterProps {
		idinscrito: number;
    nome: string;
    email: string;
    telefone: string;
    localidade: string;
    filaespera:boolean;
		informar:boolean;
		whatsapp:boolean;
}

interface ILocationsProps {
	idculto: string;
	descricao: string;
	filaespera: boolean;
	tipoFila: string;
	existeFilaEspera: boolean;
}


const ListRegister:React.FC = () =>{
	const link = useLocation<ILocationsProps>();
	const [dados, setDados] = useState<IRegisterProps[]>([]);
	const [descricaoCulto, setDescricaoCulto] = useState('');
	const [tipoFila, setTipoFila] = useState('');
	const [existeFilaEspera, setExisteFilaEspera] = useState(false);
	const [enablePrint, setEnablePrint] = useState(true);
	const confirm = useConfirm();
	const { signOut } = useAuth();
	let linhaPar = 1;
	
	const colors = ['#01579B', '#004D40', '#1B5E20', '#E65100', '#4E342E'];

	function getRandomInt(min:number, max:number) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min;
	}


	useEffect(() => {
		
		setDescricaoCulto(link.state.descricao);
		setExisteFilaEspera(link.state.existeFilaEspera);
		
		setTipoFila(link.state.tipoFila);
		api.post('registereds/worship/', {
			'culto_idculto': link.state.idculto,
			'filaespera':link.state.filaespera,
		})
			.then((response) =>{
				setDados(response.data);
			})
			.catch((errors) => {
				if(errors.response.status === 401){
					signOut();
				}
				
			});

	
	},[link.state.descricao, 
		link.state.existeFilaEspera, 
		link.state.filaespera,
		link.state.idculto,
		link.state.tipoFila,
		signOut]);
	
	const handleDelete = useCallback((value:number) => {
		const infoInscricao = dados.filter((e) => e.idinscrito === value);
		confirm({ title: 'Atenção',  description: `Você tem certeza que deseja deletar o registro de ${infoInscricao[0].nome}`, cancellationText: 'Cancelar' })
			.then(() => {
				api.put('/registereds/delete/', {
					idinscrito:value,
					excluir: true, 
				})
					.then(() => {
						
						setDados(dados.filter((e) => e.idinscrito !== value));
					})
					.catch(() => console.log('Não foi possível deletar'));
				
			})
			.catch(() => console.log('Deletion cancelled.'));
	}, [dados, confirm]);


	const handleChangeRow = useCallback((idinscrito:number) => {
		let mensagem = '';
		const infoInscricao = dados.filter((e) => e.idinscrito === idinscrito);
		if(link.state.filaespera === true){
			mensagem = `Você tem certeza que deseja incluir o registro de ${infoInscricao[0].nome} para lista oficial do culto?`;
		} else {
			mensagem = `Você tem certeza que deseja incluir na fila de espera o registro de ${infoInscricao[0].nome}?`;
		}
		
		confirm({ title: 'Atenção',  description: mensagem, cancellationText: 'Cancelar' })
			.then(() => {
				api.put('/registereds/queue', {
					idinscrito,
					filaespera: !link.state.filaespera, 
					informar: (link.state.filaespera?true:false)	
				})
					.then((response) => {
						setDados(dados.filter((e, index) => e.idinscrito !== idinscrito));
					})
					.catch(() => console.log('Não foi possível deletar'));
				
			})
			.catch(() => console.log('Deletion cancelled.'));

		
		
	}, [dados, confirm, link.state.filaespera]);


	


	const handleInformRegister = useCallback((idinscrito:number) => {
		let mensagem = '';
		const infoInscricao = dados.filter((e) => e.idinscrito === idinscrito);
		if(infoInscricao[0].informar === true){
			mensagem = `Você quer marcar ${infoInscricao[0].nome} como registro informado?`;
		} else {
			mensagem = `Você quer marcar ${infoInscricao[0].nome} como registro para informar?`;
		}
		
		confirm({ title: 'Atenção',  description: mensagem, cancellationText: 'Cancelar' })
			.then(() => {
				api.put('/registereds/inform', {
					idinscrito,
					informar: !infoInscricao[0].informar
				})
					.then((response) => {
						
						setDados( dados => {
							const informacoes = [...dados];
							const indexFind = dados.findIndex((e, index) => e.idinscrito === idinscrito);
							
							informacoes[indexFind] = response.data;
							return informacoes;
						});
						
					})
					.catch(() => console.log('Não foi possível deletar'));
				
			})
			.catch(() => console.log('Deletion cancelled.'));

		
		
	}, [dados, confirm]);


	const handleHiddenProperty = useCallback((idinscrito:number) =>{
		if(window.matchMedia('(max-width: 800px)').matches){
			const myElement: HTMLElement | null = document.getElementById(`action_${idinscrito}`);
			if(myElement){
				if(myElement.style.display === 'flex'){
					myElement.style.display = 'none';
				} else {
					myElement.style.display = 'flex';
				}
			}
		}
	}, []);

	const handleSendWhatsAppMenssage = useCallback((value:string) => {
		
		let telefone = value.replace(/ /g,'');
		telefone = telefone.replace(/-/g,'');
		telefone = telefone.replace('(','');
		telefone = telefone.replace(')','');
		telefone = `55${telefone}`;
		console.log(telefone);
		window.open(`https://api.whatsapp.com/send?phone=${telefone}&text=Ol%C3%A1%2C%20sua%20inscri%C3%A7%C3%A3o%20est%C3%A1%20confirmada%20para:%20*${descricaoCulto}*.%20Deus%20aben%C3%A7oe!`,'_blank');
		
	}, [descricaoCulto]);


	const handlePrint = useCallback(()=>{
		setEnablePrint(false);
		api.post('/registereds/report', 
			{
				'culto_idculto':link.state.idculto,
				'filaespera': false
			}
		)
			.then((response)=>{
				window.open(response.data.caminho,'_blank');
				setEnablePrint(true);
			})
			.catch(() => {
				console.log('erro ao imprimir');
				setEnablePrint(true);
			});
	}, [link.state.idculto]);
	return ( 
		<Container>
			<Navbar />
			<InfoCulto>
				{descricaoCulto} <span>{tipoFila}</span>
			</InfoCulto>
			<Content>
				
				{dados.length === 0 && (
					<p>Nenhuma inscrição registrada</p>
				)}

				{existeFilaEspera===true && (
					<LinkToNext>
						<Link to={{
							pathname: 'listRegisters',
							state: {
								descricao: descricaoCulto,
								idculto: link.state.idculto,
								filaespera: true,
								tipoFila: 'Fila de Espera',
								existeFilaEspera: false 

							}
						}}
						onClick={() => window.location.reload()}
						>Ir para Fila de Espera</Link>
					</LinkToNext>
				)}	


				{link.state.filaespera===true && (
					<LinkToNext>
						<Link to={{
							pathname: 'listRegisters',
							state: {
								descricao: descricaoCulto,
								idculto: link.state.idculto,
								filaespera: false,
								tipoFila: '',
								existeFilaEspera: true 

							}
						}}
						onClick={() => window.location.reload()}
						>Ir para Fila Oficial</Link>
					</LinkToNext>
				)}	
				{(link.state.tipoFila==='' && enablePrint === true ) &&
					
					<Imprimir onClick={handlePrint}>Imprimir <Print/></Imprimir>
				}
				{dados.length > 0 && (
					<Table>
						<Linha backgroundColor="#060b26" fontColor="#FAFAFA" cabecalho={true}>
							<Coluna tamanhoCol={50} sizeMobile={40}></Coluna>
							<ConjuntoColunas >
								<Coluna tamanhoCol={200} sizeMobile={280}>Nome</Coluna>
								<Coluna tamanhoCol={300} sizeMobile={280} >E-mail</Coluna>
								<Coluna tamanhoCol={200} sizeMobile={280} justifyContent='center'>Telefone</Coluna>
							</ConjuntoColunas>
							<Coluna  justifyContent='center' tamanhoCol={150}>Ações</Coluna>
						</Linha>
						{dados.map((info) =>(
						
							<Linha key={info.idinscrito} estiloLinha={linhaPar++} >
								<Coluna tamanhoCol={50} sizeMobile={40} justifyContent='center'>
									<LetraNome onClick={()=> handleHiddenProperty(info.idinscrito)}
										backgroundColor={colors[getRandomInt(0,5)]}>{info.nome.charAt(0)}</LetraNome>
								</Coluna>
								<ConjuntoColunas >
									<Coluna tamanhoCol={200} sizeMobile={280}>{info.nome}</Coluna>
									<Coluna tamanhoCol={300} sizeMobile={280}>{info.email}</Coluna>
									<Coluna tamanhoCol={200} sizeMobile={280} justifyContent='center'>
										{info.whatsapp === true ?
										
											<WhatsApp onClick={()=>handleSendWhatsAppMenssage(info.telefone)}><WP />{info.telefone}</WhatsApp>
											: <span>{info.telefone}</span>	
										}
										
									</Coluna>
								</ConjuntoColunas>
								<Coluna id={`action_${info.idinscrito}`}  hideCollumn={true} justifyContent='center' tamanhoCol={150}>
									<ButtonInformado marcarInformar={info.informar} onClick={()=>{handleInformRegister(info.idinscrito);}}>
										<CheckIcon />
									</ButtonInformado>
									<ButtonMudarFila onClick={()=>{handleChangeRow(info.idinscrito);}}>
										<AssignmentIcon />
									</ButtonMudarFila>
								
									<ButtonDelete onClick={()=>{handleDelete(info.idinscrito);}}>
										<DeleteIcon />
									</ButtonDelete>
								</Coluna>
							</Linha>
						))}
					</Table>
				)}
			</Content>
		</Container>
	);
};

export default ListRegister;