import styled, { css } from 'styled-components';

interface SidebarProps {
  active:boolean;
}

export const Container = styled.div`
  display: flex;
  
`;

export const LinkNavBar = styled.div`
  background-color: #060b26;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex: 1;
  

  button {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    border: 0;
  }

`;

export const NavBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  > img {
    width: 180px;
    margin-left: 10px;
  }
  
`;

export const NavBarContentUser = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 18px;
    color: white;
   img {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  @media(max-width: 800px) {
   display: none; 
  }
}
button {
    margin-left: auto;
    background: transparent;
    border: 0px solid white;
    margin-right: 70px;
    
    
    svg {
      color: white;
      width: 20px;
      height: 20px;
      
    }
  }
`;

export const NavbarContainer = styled.nav<SidebarProps>`
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;

  ${(props) =>
		props.active &&
    css`
      left: 0;
      transition: 350ms;
  `}

`;
export const NavMenuItem = styled.ul`
  width: 100%;
`;

export const LiPerfil = styled.li`

  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
@media(min-width: 800px) {
   display: none; 
  }
`;

export const NavBarToogle = styled.li`
  margin-left: 1.0rem;
  font-size: 2rem;
  background: none;
  list-style: none;
  width: 100%;
  background-color: #060b26;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  button{
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: 40px;
    border: 0;
    > img {
      
      width: 180px;
      
    }
}
`;
export const Li = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 60px;

  a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;

    &:hover{
      background-color: #1a83ff;
    }

  }

  span {
    margin-left: 16px;
    font-family: 'Lato';
  }
`;
