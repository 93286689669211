import React from 'react';
import { ConfirmProvider } from 'material-ui-confirm';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { IconProvider } from './icon';



const AppProvider: React.FC = ({ children }) => (
	<AuthProvider>
		
		<IconProvider colorValue="#ff9000">
			<ToastProvider>
				<ConfirmProvider>{children}</ConfirmProvider>
			</ToastProvider>
		</IconProvider>
		
	</AuthProvider>
);

export default AppProvider;
