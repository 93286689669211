import React from 'react';
import {
	Route as ReactRoute,
	RouteProps as ReactRouteProps,
	Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
	isPrivate = false,
	component: Component,
	...rest
}) => {
	const { user } = useAuth();

	return (
		<ReactRoute
			{...rest}
			render={({ location }) => {
				return isPrivate === !!user ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: isPrivate ? '/' : '/dashboard',
							state: { from: location },
						}}
					/>
				);
			}}
		/>
	);
};

export default Route;
