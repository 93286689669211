import React, { useCallback, useRef, ChangeEvent } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FiMail, FiLock, FiUser, FiCamera, FiArrowLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Container, Content, AvatarInput } from './styles';
import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

interface ProfileFormDate {
  name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
	const formRef = useRef<FormHandles>(null);
	const { addToast } = useToast();
	const history = useHistory();

	const { user, updateUser } = useAuth();
	
	const handleAvatarChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			console.log(e.target.size);
			if (e.target.files) {
				const data = new FormData();
				data.append('avatar', e.target.files[0]);
				api.patch('/users/avatar', data).then((response) => {
					updateUser(response.data);
					addToast({
						type: 'success',
						title: 'Avatar atualizado',
					});
				});
			}
		},
		[addToast, updateUser],
	);
	const handleSubmit = useCallback(
		async (data: ProfileFormDate) => {
			try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
        	name: Yup.string().required('Nome obrigatório'),
        	email: Yup.string()
        		.required('E-mail obrigatório')
        		.email('Digite um email válido'),

        	old_password: Yup.string(),

        	password: Yup.string().when('old_password', {
        		is: (val) => !!val.lenght,
        		then: Yup.string().required('Campo Obrigatório').min(6, 'minimo 6'),
        		otherwise: Yup.string(),
        	}),
        	password_confirmation: Yup.string()
        		.when('old_password', {
        			is: (val) => !!val.lenght,
        			then: Yup.string().required('Campo Obrigatório'),
        			otherwise: Yup.string(),
        		})
        		.oneOf([Yup.ref('password'), undefined], 'Senhas não conferem'),
        });

        await schema.validate(data, {
        	abortEarly: false,
        });

        const formData = {
        	name: data.name,
        	email: data.email,
        	...(data.old_password
        		? {
        			old_password: data.old_password,
        			password: data.password,
        			password_confirmation: data.password_confirmation,
        		}
        		: {}),
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data);

        history.push('/dashboard');

        addToast({
        	type: 'success',
        	title: 'Perfil atualizado',
        	description: 'Perfil atualizado',
        });
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
				}
				addToast({
					type: 'error',
					title: 'Erro na atualização',
					description: 'Ocorreu um erro ao atualizar perfil',
				});
			}
		},
		[addToast, history, updateUser],
	);

	return (
		<Container>
			<header>
				<div>
					<Link to="/dashboard">
						<FiArrowLeft />
					</Link>
				</div>
			</header>
			<Content>
				<Form
					ref={formRef}
					onSubmit={handleSubmit}
					initialData={{
						name: user.name,
						email: user.email,
					}}
				>
					<AvatarInput>
					
						<img src={user.avatar_url} alt={user.name} />
						<label htmlFor="avatar">
							<FiCamera color="#FFF"/>
							<input
								type="file"
								name="avatar"
								id="avatar"
								onChange={handleAvatarChange}
								accept="image/*"
								
							/>
						</label>
					</AvatarInput>
					<h1>Meu Perfil</h1>
					<Input name="name" icon={FiUser} type="text" placeholder="Nome" />
					<Input name="email" icon={FiMail} type="text" placeholder="E-mail" />
					<Input
						containerStyle={{ marginTop: 24 }}
						icon={FiLock}
						name="old_password"
						type="password"
						placeholder="Senha Atual"
					/>

					<Input
						icon={FiLock}
						name="password"
						type="password"
						placeholder="Nova Senha"
					/>

					<Input
						icon={FiLock}
						name="password_confirmation"
						type="password"
						placeholder="Confirmar Senha"
					/>

					<Button type="submit">Confirmar Mudanças</Button>
				</Form>
			</Content>
		</Container>
	);
};

export default Profile;
