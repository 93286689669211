import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import ProgressBar from './BarraPorcentagemCulto';
import {
	Container, 
	Content, 
	InternalContent,
	TitleCulto,
	Cards,
	TopoCard,
	BottonCard,
	Card,
	Descricao,
	Vagas,


} from './styles';

import Navbar from '../../components/Navbar';
import api from '../../services/api';


interface ICountCultoAtual {
		idculto: number;
    descricao: string;
    data: Date;
    hora: string;
    permitefilaespera: boolean;
    igreja_idigreja: number
		vagas: number;
		church?: {
			idigreja: number;
			descricao: string;
			ativo: boolean;
		}
  
}

const Dashboard:React.FC = () => {
	const [cultoAtual, setCultoAtual] = useState<ICountCultoAtual[]>([]);
	const { signOut } = useAuth();
	
	

	useEffect(()=>{
		api.post<ICountCultoAtual[]>('worships/worship/date', {
			'data': new Date()
		})
			.then((response) =>{
				setCultoAtual(response.data); 
				
			})
			.catch((errors) => {
				
				if(errors.response.status === 401){
					signOut();
				}
				
			});
		
	}, [signOut]);


	
	
	return (
		<Container>
			<Navbar />
			<Content>
				
				<InternalContent>
					<TitleCulto>Cultos Ativos</TitleCulto>
					<Cards>
						
						{cultoAtual.map((e) => (
							<Card key={e.idculto}>
								<TopoCard>
									<Descricao> 
										<span>{e.descricao}{e.idculto}</span>
										{e.church?.descricao}
									</Descricao>
									
									<Vagas>{e.vagas}</Vagas>
								</TopoCard>
								<BottonCard>
									<span>Inscrições</span>
									<ProgressBar idculto={e.idculto} descricaoCulto={e.descricao} />
									
								</BottonCard>
							</Card>
						))}
						
						
					</Cards>
				</InternalContent>
			
			</Content>
		</Container>
	);
};


export default Dashboard;