import styled, {} from 'styled-components';


export const Container = styled.div``;


export const Content = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  color: black;
  
 
`;


export const Title = styled.div`
 display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
`;